<template>
  <div ref="container" class="w-screen max-h-screen flex justify-start">
    <Video v-if="isLoaded" :openned="true" :width="cwidth" :height="cheight" />
  </div>
</template>
<script>
import Video from '@/components/Video.vue'
export default {
  components: { Video },
  methods: {
    height() { return this.$refs.container ? this.$refs.container.clientHeight : 0 },
    width() { return this.$refs.container ? this.$refs.container.clientWidth : 0 },
  },
  mounted() {
    this.cheight = this.height()
    this.cwidth = this.width()
    this.isLoaded = true
  },
  data() {
    return {
      isLoaded: false,
      cwidth: 0,
      cheight: 0,
    }
  },
}
</script>
<style lang="scss" scoped></style>
