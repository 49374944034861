<template>
  <div class="standard-sidebar">
    <article class="text-center">
      <h1>Actions</h1>
      <p>Here's how local residents, local governments, community organizations and businesses are taking climate action in the Basin.</p>
      <p>
        <select v-model="locationFilter" class="w-32 mr-2 mt-2">
          <option value="">All Locations</option>
          <option v-for="location in possibleLocations"
                  :key="possibleLocations.indexOf(location)"
                  :value="location">
                  {{ location }}
          </option>
        </select>

        <select v-model="typeFilter" class="w-32 mr-2 mt-2">
          <option value="">Categories</option>
          <option v-for="type in possibleTypes"
                  :key="possibleTypes.indexOf(type)"
                  :value="type">
                  {{ type }}
          </option>
        </select>

        <select v-model="impactAreaFilter" class="w-32 mt-2">
          <option value="">All Impacts</option>
          <option v-for="impact in possibleImpactAreas"
                  :key="possibleImpactAreas.indexOf(impact)"
                  :value="impact">
                  {{ impact }}
          </option>
        </select>
      </p>
    </article>
    <div class="flex flex-wrap justify-center">
      <ActionItem
        v-for="action in actionsFiltered"
        :key="actions.indexOf(action)"
        :title="action.title"
        :description="action.description"
        :image="action.image"
        :link="action.link"
        :location="action.location"
        :type="action.type"
        :impactArea="action.impactArea"
      />
    </div>
  </div>
</template>

<script>
import ActionItem from '@/components/ActionItem.vue'

const meta = {
  title: 'Actions',
  description: 'How local residents, local governments, community organizations and businesses are taking climate action in the Basin.'
}

export default {
  metaInfo: {
    title: meta.title,
    meta: [
      { property: 'description', content: 'How local residents, local governments, community organizations and businesses are taking climate action in the Basin.', vmid: 'description' },
      { property: 'og:description', content: 'How local residents, local governments, community organizations and businesses are taking climate action in the Basin.', vmid: 'og:description' },
      { property: 'twitter:title', content: meta.title, vmid: 'twitter:title' },
      { property: 'twitter:description', content: 'How local residents, local governments, community organizations and businesses are taking climate action in the Basin.', vmid: 'twitter:description' }
    ]
  },
  data () {
    return {
    }
  },
  computed: {
    actions () {
      return this.$store.getters.actions
    },
    actionsFiltered () {
      return this.$store.getters.actionsFiltered
    },
    possibleImpactAreas () { return Array.from(new Set(this.actionsFiltered.map(i => i.impactArea).reduce((acc, v) => [...acc, ...v]).sort())) },
    possibleTypes () { return Array.from(new Set(this.actionsFiltered.map(i => i.type).reduce((acc, v) => [...acc, ...v]).sort())) },
    possibleLocations () { return Array.from(new Set(this.actionsFiltered.map(i => i.location).reduce((acc, v) => [...acc, ...v]).sort())) },
    locationFilter: {
      get () { return this.$store.state.actionLocationFilter },
      set (value) { this.$store.commit('ACTION_LOCATION', value) }
    },
    typeFilter: {
      get () { return this.$store.state.actionTypeFilter },
      set (value) { this.$store.commit('ACTION_TYPE', value) }
    },
    impactAreaFilter: {
      get () { return this.$store.state.actionImpactAreaFilter },
      set (value) { this.$store.commit('ACTION_IMPACT', value) }
    }
  },
  components: { ActionItem },
  mounted () {
    this.$store.commit('ACTION_LAYER_VISIBILITY', true)
  },
  destroyed () {
    this.$store.commit('ACTION_LAYER_VISIBILITY', false)
    this.$store.dispatch('resetActions')
  }
}
</script>

<style>
</style>
