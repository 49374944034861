<template>
<div>
<!-- <div class="bg-red text-center align-center">
<span id="Notice"><b>Please Note:</b> The Community Climate Profiles have not yet been updated with the new CMIP6 climate data. The current versions of these documents are based on CMIP5 data and therefore do not include the best available science. Check back in fall 2022 for updated Community Climate Profiles.</span>
</div> -->
  <div class="standard-sidebar">
    <div class="text-center pb-4">
      <h1>Communities</h1>
    </div>
    <article>
      <p>
        The Columbia Basin-Boundary region is diverse geographically, culturally, and economically. Climate change will affect different parts of our region in different ways.
      </p>
      <p>
        Our series of Community Climate Profiles provide a regional overview of climate change, location-specific data, a summary of the expected impacts of climate change, and examples of climate action in the area surrounding a community.
      </p>
      <p>
        A profile is available for each municipality and First Nation community in the region, as well as five select high-elevation sites.
      </p>
      <p class="teaser">
        Click a marker on the map, or select a location below, to view a climate profile.
      </p>
      <div class="text-center">
        <select v-model="selectedCommunity" class="w-64">
          <option value="">Select a location...</option>
          <option v-for="location in locations"
                  :key="locations.indexOf(location)"
                  :value="location">
                  {{ location.name }}
          </option>
        </select>
        <div class="dlbutton">
          <a class="" v-if="selectedCommunity !== ''" @click="trackDownload" :href="`/profiles/${this.selectedCommunity.pdf}`" target="_blank">Download</a>
        </div>
      </div>
    </article>
  </div>
  </div>
</template>

<script>
const meta = {
  title: 'Communities',
  description: 'Our series of Community Climate Profiles provide a regional overview of climate change, location-specific data, a summary of the expected impacts of climate change, and examples of climate action in the area surrounding the community.'
}

export default {
  metaInfo () {
    return {
      title: meta.title,
      meta: [
        { property: 'description', content: meta.description, vmid: 'description' },
        { property: 'og:description', content: meta.description, vmid: 'og:description' },
        { property: 'twitter:title', content: meta.title, vmid: 'twitter:title' },
        { property: 'twitter:description', content: meta.description, vmid: 'twitter:description' }
      ]
    }
  },
  data () {
    return {
      selectedCommunity: '',
      baseUrl: process.env.BASE_URL,
      locations: [
        { name: 'Bugaboo Provincial Park', type: 'park', pdf: 'ClimateProfile_BugabooProvincialPark.pdf', location: [-116.759, 50.762] },
        { name: 'Canal Flats', pdf: 'ClimateProfile_CanalFlats.pdf', location: [-115.81, 50.155] },
        { name: 'Castlegar', pdf: 'ClimateProfile_Castlegar.pdf', location: [-117.665, 49.326] },
        { name: 'Cranbrook', pdf: 'ClimateProfile_Cranbrook.pdf', location: [-115.765, 49.512] },
        { name: 'Creston', pdf: 'ClimateProfile_Creston.pdf', location: [-116.512, 49.096] },
        { name: 'Elk Lakes Provincial Park', type: 'park', pdf: 'ClimateProfile_ElkLakesProvincialPark.pdf', location: [-115.113, 50.551] },
        { name: 'Elkford', pdf: 'ClimateProfile_Elkford.pdf', location: [-114.924, 50.024] },
        { name: 'Fernie', pdf: 'ClimateProfile_Fernie.pdf', location: [-115.063, 49.506] },
        { name: 'Fruitvale', pdf: 'ClimateProfile_Fruitvale.pdf', location: [-117.548, 49.114] },
        { name: 'Golden', pdf: 'ClimateProfile_Golden.pdf', location: [-116.968, 51.295] },
        { name: 'Grand Forks', pdf: 'ClimateProfile_GrandForks.pdf', location: [-118.444, 49.032] },
        { name: 'Grano Creek Weather Station', type: 'weather', pdf: 'ClimateProfile_GranoCreekWeatherStation.pdf', location: [-118.667, 49.55] },
        { name: 'Greenwood', pdf: 'ClimateProfile_Greenwood.pdf', location: [-118.677, 49.097] },
        { name: 'Invermere', pdf: 'ClimateProfile_Invermere.pdf', location: [-116.03, 50.506] },
        { name: 'Kaslo', pdf: 'ClimateProfile_Kaslo.pdf', location: [-116.912, 49.913] },
        { name: 'Kimberley', pdf: 'ClimateProfile_Kimberley.pdf', location: [-115.984, 49.684] },
        { name: 'Kokanee Provincial Park', type: 'park', pdf: 'ClimateProfile_KokaneeProvincialPark.pdf', location: [-117.195, 49.768] },
        { name: 'Midway', pdf: 'ClimateProfile_Midway.pdf', location: [-118.773, 49.009] },
        { name: 'Montrose', pdf: 'ClimateProfile_Montrose.pdf', location: [-117.591, 49.079] },
        { name: 'Nakusp', pdf: 'ClimateProfile_Nakusp.pdf', location: [-117.802, 50.239] },
        { name: 'Nelson', pdf: 'ClimateProfile_Nelson.pdf', location: [-117.294, 49.492] },
        { name: 'New Denver', pdf: 'ClimateProfile_NewDenver.pdf', location: [-117.372, 49.992] },
        { name: 'Radium Hot Springs', pdf: 'ClimateProfile_RadiumHotSprings.pdf', location: [-116.074, 50.62] },
        { name: 'Revelstoke', pdf: 'ClimateProfile_Revelstoke.pdf', location: [-118.197, 50.992] },
        { name: 'Roger\'s pass', pdf: 'ClimateProfile_RogersPass.pdf', location: [-117.52, 51.301] },
        { name: 'Rossland', pdf: 'ClimateProfile_Rossland.pdf', location: [-117.803, 49.077] },
        { name: 'Salmo', pdf: 'ClimateProfile_Salmo.pdf', location: [-117.279, 49.194] },
        { name: 'Shuswap Indian Band', pdf: 'ClimateProfile_ShuswapIndianBand.pdf', location: [-116.021, 50.538] },
        { name: 'Silverton', pdf: 'ClimateProfile_Silverton.pdf', location: [-117.357, 49.952] },
        { name: 'Slocan', pdf: 'ClimateProfile_Slocan.pdf', location: [-117.47, 49.767] },
        { name: 'Sparwood', pdf: 'ClimateProfile_Sparwood.pdf', location: [-114.883, 49.734] },
        { name: 'Trail', pdf: 'ClimateProfile_Trail.pdf', location: [-117.713, 49.096] },
        { name: 'Valemount', pdf: 'ClimateProfile_Valemount.pdf', location: [-119.266, 52.829] },
        { name: 'Warfield', pdf: 'ClimateProfile_Warfield.pdf', location: [-117.753, 49.091] },
        { name: 'Yakan Nukiy', pdf: 'ClimateProfile_YaqanNukiy.pdf', location: [-116.528, 49.058] },
        { name: 'Yaq̓itʔa·knuqⱡi‘it', pdf: 'ClimateProfile_Yaq̓itʔa·knuqⱡi‘it.pdf', location: [-115.095, 49.068] },
        { name: 'ʔakisq̓nuk', pdf: 'ClimateProfile_ʔakisq̓nuk.pdf', location: [-115.918, 50.428] },
        { name: 'ʔaq̓am', pdf: 'ClimateProfile_ʔaq̓am.pdf', location: [-115.735, 49.634] }
      ]
    }
  },
  mounted () {
    this.$root.$emit('map/add-communities', this.locations)
  },
  destroyed () {
    this.$root.$emit('map/remove-communities')
  },
  methods: {
    trackDownload () {
      this.$matomo.trackEvent('Downloads', 'Download Community PDF', this.selectedCommunity.name)
    }
  },
  watch: {
    selectedCommunity (newCommunity) {
      this.$root.$emit('map/zoom-to', {
        lon: newCommunity.location[0],
        lat: newCommunity.location[1],
        zoom: 11
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dlbutton {
  @apply mt-8 text-center text-white;

  a {
    @apply bg-green text-white font-bold py-4 px-6;
  }

  a:hover {
    @apply bg-green-dark no-underline;
  }
}

#Notice {
  color:white;
  font-size:14px;

}
</style>
